<template>
  <div
    v-if="showMap"
    class="modal"
  >
    <div class="modal-inner">
      <div
        class="close-btn"
        @click="$emit('close',false)"
      >
        <i class="el-icon-close size20 white bold"></i>
      </div>
      <div class="title row s_center pb10 border-b">

        <div class="dis row  ">
          <img
            class="icon1"
            src="../assets/icon/oldEstate_weizhi.png"
            alt=""
          >
          <div>
            <span class="first">{{estateName}}</span>
            <p class="second mt10">{{estateAddress}}</p>
          </div>

        </div>
        <span class="three"></span>
        <div class="shop row s_start">
          <img
            class="icon3"
            src="../assets/icon/oldEstate_shop.png"
            alt=""
          >
          <div>
            <span class="five">{{name}}</span>
            <p class="six mt10">{{address}}</p>
          </div>

        </div>
        <!-- <div class="flex size14">
          sadsad
        </div> -->
      </div>
      <div class="map-div ">
        <baidu-map
          :center="{ lat: this.lat, lng: this.lng }"
          :zoom="17"
          @ready="loadMap"
          style="height: 740px; width: 319px"
          :scroll-wheel-zoom="false"
        >
          <bm-view style="width: 740px; height: 319px"></bm-view>
          <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
          <!-- <bm-label :offset="{width:5,height:-5}"
                          :massClear="false"
                          :labelStyle="{border:'none',padding:0,background:'none'}"
                          :content="titleLabel"
                          :position="{lng:lng, lat:lat}" /> -->
          <bm-overlay
            v-if="lat"
            pane="labelPane"
            class="zone"
            @draw="draw"
          >
            <div style="
                width: 8px;
                height: 8px;
                background: #00ae66;
                border: 2px #fff solid;
                border-radius: 6px;
                position: relative;
                left: 0;
                top: 0;
              ">
              <div style="
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 22px;
                  color: #ffffff;
                  padding: 0px 26px;
                  position: absolute;
                  left: -300%;
                  bottom: 17px;
                  width: max-content;
                ">
                <div style="
                    background: #00ae66;
                    position: relative;
                    left: -50%;
                    padding: 2px 10px;
                    border-radius: 11px;
                  ">
                  <p class="row c_center">
                    <img
                      style="width:18px;height:18px;margin-right:2px"
                      src="../assets/icon/oldEstate_shop.png"
                      alt=""
                    >
                    <span style="font-size:12px">{{ name }}</span>
                  </p>
                  <div style="
                      width: 0;
                      height: 0;
                      border-left: 7px solid transparent;
                      border-right: 7px solid transparent;
                      border-top: 12px solid #00ae66;
                      position: absolute;
                      left: 50%;
                      margin-left: -5px;
                      bottom: -6px;
                    "></div>
                </div>
              </div>
            </div>
          </bm-overlay>
          <bm-overlay
            v-if="estateLat"
            pane="labelPane"
            class="zone"
            @draw="drawEstate"
          >
            <div style="
                width: 8px;
                height: 8px;
                background: #00ae66;
                border: 2px #fff solid;
                border-radius: 6px;
                position: relative;
                left: 0;
                top: 0;
              ">
              <div style="
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 22px;
                  color: #ffffff;
                  padding: 0px 26px;
                  position: absolute;
                  left: -300%;
                  bottom: 17px;
                  width: max-content;
                ">
                <div style="
                    background: #00ae66;
                    position: relative;
                    left: -50%;
                    padding: 2px 10px;
                    border-radius: 11px;
                  ">
                  <p class="row c_center">
                    <img
                      style="width:18px;height:18px;margin-right:2px"
                      src="../assets/icon/oldEstate_weizhi.png"
                      alt=""
                    >
                    <span style="font-size:12px">{{ estateName }}</span>
                  </p>

                  <div style="
                      width: 0;
                      height: 0;
                      border-left: 7px solid transparent;
                      border-right: 7px solid transparent;
                      border-top: 12px solid #00ae66;
                      position: absolute;
                      left: 50%;
                      margin-left: -5px;
                      bottom: -6px;
                    "></div>
                </div>
              </div>
            </div>
          </bm-overlay>
          <!-- <bm-marker :position="mPoint"></bm-marker> -->
        </baidu-map>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "name",
    "lat",
    "lng",
    "address",
    "showMap",
    "estateName",
    "estateLat",
    "estateLng",
    "estateAddress",
  ],
  methods: {
    loadMap({ BMap, map }) {
      this.BMap = BMap;
      this.map = map;
      this.mPoint = new BMap.Point(this.lng, this.lat);
    },
    draw({ BMap, map, el }) {
      console.log("estataLat>>", this.estateLat);
      const pixel = map.pointToOverlayPixel(new BMap.Point(this.lng, this.lat));
      el.style.left = pixel.x - 4 + "px";
      el.style.top = pixel.y - 4 + "px";
    },
    drawEstate({ BMap, map, el }) {
      const pixel = map.pointToOverlayPixel(
        new BMap.Point(this.estateLng, this.estateLat)
      );
      el.style.left = pixel.x - 4 + "px";
      el.style.top = pixel.y - 4 + "px";
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
@keyframes ani-modal {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  80% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.modal-inner {
  width: 760px;
  height: 440px;
  box-sizing: border-box;
  animation: ani-modal 500ms;
  animation-fill-mode: both;
  margin: -220px 0 0 -330px;
  transition: all 500ms;
  .title {
    margin: 30px 10px 0;
  }
}
.close-btn {
  width: 30px;
  height: 30px;
  position: absolute;
  right: -15px;
  top: -15px;
  background-color: #434343;
  border-radius: 15px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  cursor: pointer;
}
.map-div {
  height: 359px;

  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
}
.city-item {
  padding: 0 20px;
  box-sizing: border-box;
}
.zone {
  width: 1px;
  height: 1px;
  background: #00ae66;
  border: 0px #fff solid;
  border-radius: 0px;
  position: relative;
  left: 0;
  top: 0;
}

.dis {
  width: 36%;
}
.icon1 {
  width: 52px;
  height: 52px;
  margin-right: 18px;
}
.first {
  font-size: 20px;
  color: #00ae66;
}
.second {
  font-size: 12px;
  color: #9399a5;
}
.three {
  margin: 0 20px;
  width: 1px;
  background: #eee;
  height: 57px;
}
.shop {
  width: 56%;
}
.icon3 {
  width: 52px;
  height: 52px;
  margin-right: 18px;
}
.five {
  font-size: 20px;
  color: #00ae66;
}
.six {
  font-size: 12px;
  color: #9399a5;
}
</style>