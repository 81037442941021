<template>
  <div class="container">
    <house-header :tradeType="tradeType" />
    <HouseDetailHeader
      :house="house"
      :tradeType="tradeType"
      :isHide="true"
    />
    <div class="content">
      <div class="row house-imgs">
        <div class="flex1 pv10">
          <Carousel
            :imgs="houseImage"
            :vrUrl="house.vrUrl"
            :videoUrl="house.videoUrl"
            :coverImage="house.coverImage"
            :videoImageUrl="house.videoImageUrl"
          />
          <div
            ref="showAgent"
            class="top60"
            v-if="recommendHouse.length > 0"
          >
            <recommend-house
              :estateName="house.name"
              tradeType="2"
              :recommend-house="recommendHouse"
            />
          </div>
          <div class="top60">
            <recommend-agent
              v-show="otherAgents.length > 0"
              title="联系经纪人"
              :show-vice-title="showViceTitle"
              :recommend-agent="otherAgents"
              :show-recommend="showRecommend"
              @show-code="toMimiWeChat"
            />
          </div>
          <div
            class="top60"
            v-if="recommendRentHouse.length > 0"
          >
            <recommend-house
              :estateName="house.name"
              tradeType="1"
              :recommend-house="recommendRentHouse"
            />
          </div>
        </div>
        <div style="width: 397px; margin-left: 65px; padding-top: 28px">
          <div class="size12 red pb20 border-b">
            <span style="font-size: 40px">{{ house.price?house.price:'未知' }}</span>
            <span>元/㎡</span>
            <span
              style="color:#ccc"
              class="bold ml10"
            >参考均价</span>
          </div>
          <div class="mt20 size12 pb15 border-b">
            <!-- <div class="mb15 row">
                            <span class="w70 whiteGray inline-b">建筑类型</span>
                            <span class="ml20 clo101">塔板结合</span>
                        </div> -->
            <div class="mb15 row">
              <span class="w70 whiteGray inline-b">物业费用</span>
              <span class="ml20 clo101">{{ house.mgtPrice ? house.mgtPrice + "元/平米/月" : "未知"}}</span>
            </div>
            <div class="mb15 row">
              <span class="w70 whiteGray inline-b">物业公司</span>
              <span class="ml20 clo101">{{ house.mgtCompany||'未知' }}</span>
            </div>
            <div class="mb15 row">
              <span class="w70 whiteGray inline-b">开发商</span>
              <span class="ml20 clo101">{{ house.devCompany }}</span>
            </div>
            <div class="mb15 row">
              <span class="w70 whiteGray inline-b">楼栋总数</span>
              <span class="ml20 clo101">{{ house.buildingNum }}栋</span>
            </div>
            <div class="mb15 row">
              <span class="w70 whiteGray inline-b">房屋总数</span>
              <span class="ml20 clo101">{{ house.roomNum }}户</span>
            </div>
            <div
              class="mb15 row"
              v-if="department.guid"
            >
              <span class="w70 whiteGray inline-b">附近门店</span>
              <p class="ml20 clo101 flex flex1 ellipsis">
                <span
                  @click="showMap=true"
                  class="blue cupo"
                >{{ department.name }}</span>
                <!-- <span>{{house.region}}</span> -->
              </p>
            </div>
          </div>
          <div class="mt20">
            <agent-data
              v-show="agentData.trueName"
              :agent-data="agentData"
              @show-code="toMimiWeChat"
            />
          </div>
          <div class="fixedAgent ">
            <div
              class="content relative"
              v-show="needRightFxied"
            >
              <div
                class="absolute"
                style="width:397px;right:0;top:10px"
              >
                <agent-data
                  v-show="agentData.trueName"
                  :agent-data="agentData"
                  @show-code="toMimiWeChat"
                />
              </div>

            </div>
          </div>

        </div>
      </div>
      <div
        class="top60"
        ref='mapHouseRef'
      >
        <map-peripheral
          v-if="house.lat && house.lng"
          :lat="house.lat"
          :lng="house.lng"
          :estateName="house.name"
        />
      </div>
      <div class="top60 pb20">
        <h1 class="mb20 size24 bold col4d">{{ house.name?house.name + "附近小区":"附近小区" }}</h1>
        <ul class="pt5 flex">
          <router-link
            :to="{
              name: 'house-estate-detail',
              query: { guid: item.guid },
            }"
            target="_blank"
            class="near-item relative cursor"
            v-for="item in nearEstate"
            :key="item.guid"
          >
            <img
              class="all"
              :src="
                item.coverImage
                  ? item.coverImage
                  : 'https://resource.fzhitong.cn/default_house_cover.png'
              "
              alt=""
            />
            <div class="absolute-house inner column c_center">
              <h1 class="white size14 bold pb5 center">{{ item.name }}</h1>
              <div
                v-show="Math.floor(item.price)"
                class="inner-price row c_center"
              >
                <span class="size12">{{
                  Math.floor(item.price) + "元/㎡"
                }}</span>
              </div>
            </div>
          </router-link>
        </ul>
      </div>
    </div>
    <qr-code-dialog
      :show-code="isShowQRCode"
      :page="4"
      :houseGuid="estateGuid"
      @update-show-code="isShowQRCode = $event"
    />
    <DepartmentLocation
      :name="department.name"
      :lat="department.lat"
      :lng="department.lng"
      :address="department.address"
      :estateName="house.name"
      :estateLat="house.lat"
      :estateLng="house.lng"
      :estateAddress="house.address"
      :showMap="showMap"
      @close="showMap=false"
    />
  </div>
</template>
<script>
import HouseHeader from "../../components/house-header";
import Constant from "../../common/Constant.js";
import HouseDetailHeader from "../../components/HouseDetailHeader.vue";
import Carousel from "../../components/Carousel.vue";
import RecommendHouse from "../../components/RecommendHouse.vue";
import DepartmentLocation from "../../components/DepartmentLocation.vue";
import RecommendAgent from "../../components/RecommendAgent.vue";
import Dialog from "../../components/Dialog.vue";
import MapPeripheral from "../../components/MapPeripheral.vue";
import AgentData from "../../components/AgentData.vue";
import houseServer from "../../services/houseServer";
import realHouseServer from "../../services/realHouseServer";
import tool from "@/utils/tool.js";
import { mapState } from "vuex";
export default {
  name: "EstateDetail",
  data() {
    return {
      showMap: false,
      needRightFxied: false,
      tradeType: "",
      lng: "",
      lat: "",
      page: 3, //楼盘
      estateGuid: "",
      showViceTitle: true,
      showRecommend: false,
      house: {},
      houseImage: [],
      recommendHouse: [],
      recommendRentHouse: [],
      agentData: {},
      otherAgents: [],
      nearEstate: [],
      isShowQRCode: false, //显示二维码

      houseTypes: [
        {
          title: "VR",
          key: 0,
          value: "vr",
          choose: true,
        },
        {
          title: "视频",
          key: 1,
          value: "video",
          choose: false,
        },
        {
          title: "图片",
          key: 2,
          value: "effect",
          choose: false,
        },
        {
          title: "户型",
          key: 3,
          value: "houseType",
          choose: false,
        },
      ],
      houseType: [
        {
          title: "图片",
          choose: true,
          source: [],
        },
        {
          title: "视频",
          choose: false,
          source: [],
        },
        {
          title: "VR",
          choose: false,
          source: [],
        },
      ],

      deleteTags: [],
      title: "",
      videoUrl: "",
      vrUrl: "",
      department: {},
    };
  },
  components: {
    HouseHeader,
    HouseDetailHeader,
    Carousel,
    RecommendHouse,
    RecommendAgent,
    QrCodeDialog: Dialog,
    MapPeripheral,
    AgentData,
    DepartmentLocation,
  },
  computed: {
    ...mapState(["location"]),
    regions() {
      if (this.house.region) {
        return this.house.region.split("-");
      }
      return [];
    },
  },

  methods: {
    onScroll() {
      let scrollTop = document.documentElement.scrollTop;
      let mapScrollTop = this.$refs.mapHouseRef.offsetTop;
      let showAgentScrollTop;
      if (this.$refs.showAgent) {
        showAgentScrollTop = this.$refs.showAgent.offsetTop;
      } else {
        showAgentScrollTop = 800;
      }

      // console.log('scrollTop>>>>',scrollTop)
      // console.log('mapScrollTop>>>>',mapScrollTop)
      if (scrollTop > showAgentScrollTop + 50) {
        if (scrollTop < mapScrollTop - 500) {
          this.needRightFxied = true;
        } else {
          this.needRightFxied = false;
        }
      } else {
        this.needRightFxied = false;
      }
    },
    toMimiWeChat(agent) {
      this.isShowQRCode = true;
      // console.log(`agent >>`, agent)
    },

    //加载小区详情
    async getOldEstateInfo() {
      const { data } = await houseServer.getOldEstateInfo(this.estateGuid);
      const { lat, lng } = data;
      this.lat = lat;
      this.lng = lng;
      this.house = data;
      let coverImage = data.coverImage || Constant.DEFAULT_HOUSE;
      this.title = data.propertyTypeDesc ? data.propertyTypeDesc : "";

      this.vrUrl = data.vrUrl;

      this.videoUrl = data.videoUrl;

      this.getViewAgent();
      this.getAroundEstate();
      this.loadDepartmentByPoint();
    }, //获取经纪人
    async getViewAgent() {
      let params = {
        estateGuid: this.estateGuid,
        lng: this.house.lng,
        lat: this.house.lat,
      };
      const { data } = await houseServer.getViewAgent(params);
      // console.log("getViewAgent", data);
      if (data.length > 0) {
        this.agentData = data[0];
        this.otherAgents = data;
      } else {
        this.agentData = {};
        this.otherAgents = [];
      }
    },
    //周边小区
    async getAroundEstate() {
      let params = {
        lng: this.house.lng,
        lat: this.house.lat,
        page: 1,
        itemsPerPage: 4,
      };
      const { data } = await houseServer.getAroundEstate(params);
      this.nearEstate = data.records;
    },
    //小区二手房
    async getOldEstateSaleHouse() {
      let params = {
        estateGuid: this.estateGuid,
        page: 1,
        itemsPerPage: 3,
        tradeType: 2,
        min: 0,
        max: 99999,
      };
      const { data } = await houseServer.getOldEstateHouse(params);
      this.recommendHouse = data.records;
    },
    //小区租房
    async getOldEstateRentHouse() {
      let params = {
        estateGuid: this.estateGuid,
        page: 1,
        itemsPerPage: 3,
        tradeType: 1,
        min: 0,
        max: 99999,
      };
      const { data } = await houseServer.getOldEstateHouse(params);
      this.recommendRentHouse = data.records;
    },
    //获取房源图片
    async getHouseresource() {
      const { data } = await realHouseServer.getHouseresource(this.estateGuid);
      this.houseImage = data;
    },
    addTeleteTags(key) {
      this.deleteTags.push(key);
    },

    deepCopy(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    async loadDepartmentByPoint() {
      const { data } = await houseServer.getNeighborhoodDepartment({
        lng: this.house.lng,
        lat: this.house.lat,
        page: 1,
        itemsPerPage: 3,
        distance: 10000,
        cityCode: this.location.cityCode,
      });
      this.department = data[0];
    },

    getTradeType() {
      const {
        params: { tradeType },
      } = this.$route;
      this.tradeType = +tradeType;
    },
  },
  watch: {
    $route(newVal, val) {
      if (newVal.params.tradeType !== val.params.tradeType) {
        this.tradeType = +newVal.params.tradeType;
      }
    },
  },
  created() {
    this.estateGuid = this.$route.query.guid;
    this.getTradeType();
    this.getHouseresource();
    this.getOldEstateInfo();
    this.getOldEstateSaleHouse();
    this.getOldEstateRentHouse();
  },
  mounted() {
    window.onscroll = tool.throttle(this.onScroll, 200);
  },
};
</script>
<style scoped>
.w70 {
  width: 70px;
}
.clo101 {
  color: #101d37;
}
.top60 {
  margin-top: 60px;
}
.near-item {
  width: 268px;
  height: 220px;
}
.near-item:not(:nth-of-type(4)) {
  margin-right: 26px;
}

.inner {
  top: 0;
  left: 0;
  width: 268px;
  height: 220px;
  background-color: rgba(0, 0, 0, 0.2);
}
.inner-price {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0px 6px;
  height: 21px;
  line-height: 21px;
  color: #fff;
}
.relative {
  position: relative;
  left: 0;
  top: 0;
}
.absolute-house {
  position: absolute;
  right: 0;
  top: 0;
  width: 268px;
}
.fixedAgent {
  position: fixed;
  top: 20px;
  left: 0;
  height: 0px;
  width: 100%;
  background: #fff;
}
</style>