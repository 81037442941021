var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"flex sb_center mb20"},[_c('h1',{staticClass:"size24 col4d bold"},[_vm._v(" "+_vm._s(_vm.estateName)+_vm._s(_vm.tradeType == 2 ? "优质二手房" : "正在出租")+" ")]),_c('span',{staticClass:"size14 cursor gray",on:{"click":_vm.toHouseList}},[_vm._v(_vm._s(_vm.tradeType == 2 ? "查看小区全部在售二手房" : "查看小区全部出租房")+" ")])]),_c('ul',{staticClass:"flex pt5"},_vm._l((_vm.recommendHouse),function(item){return _c('router-link',{key:item.guid,staticClass:"sale-hosue-item mr10",attrs:{"title":item.title,"to":{
        name: _vm.tradeType == 2 ? 'second-house-detail' : 'rent-house-detail',
        query: {
          guid: item.guid,
          tradeType: _vm.tradeType,
        },
      },"target":"_blank"}},[_c('div',{staticClass:"pb10 relative",staticStyle:{"height":"160px"}},[_c('img',{staticClass:"all",attrs:{"src":item.coverImage
              ? item.coverImage
              : 'https://resource.fzhitong.cn/default_house_cover.png',"alt":""}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tradeType == 2 && item.price),expression:"tradeType == 2 && item.price"}],staticClass:"absolute sale-price size12"},[_vm._v(" "+_vm._s("￥" + item.price + "万")+" ")])]),_c('p',{staticClass:"ellipsis size14 bold black pb5",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"flex sb_center"},[_c('div',{staticClass:"flex size12 gray"},[(item.buildingArea)?_c('span',[_vm._v(_vm._s(item.buildingArea + "平/"))]):_vm._e(),(item.room)?_c('span',[_vm._v(_vm._s(item.room + "室"))]):_vm._e(),(item.hall)?_c('span',[_vm._v(_vm._s(item.hall + "厅"))]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tradeType == 1 && item.rentPrice),expression:"tradeType == 1 && item.rentPrice"}],staticClass:"size12 col4d flex s_center"},[_c('span',{staticClass:"red"},[_vm._v(_vm._s(item.rentPrice))]),_c('span',[_vm._v("元/月")])])])])}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }